<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { alias1bddc4d174174d47a6dfb0437dace856 } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000672')},
        {value: 'inventoryImport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000701')},
        {value: 'productGoodsSuggestPriceImport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1000723')},
        {value: 'productGoodsStatusImport', name: alias1bddc4d174174d47a6dfb0437dace856.t('key1005536')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
